
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.page {
  div[class*='BannerStandard_textContent'] {
    @media (max-width: map-get($grid-breakpoints, 'md')) {
      h1 {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -2.16px;
        width: 100% !important;
      }
    }
  }
}
